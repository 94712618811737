<!--TEMPORARY FILE PREP FOR GST SETTINGS-->
<template>
  <b-card title="Test Check Stock">
    <b-col cols="12">
      <b-row>
        <b-col cols="12">
          <div class="cart__area">
              <div class="card__area-body">
                <div class="cart__area-columns mb-1">
                  <span>
                    Item<span style="color:red">(*)</span>
                  </span>
                  <span>
                    Quantity
                  </span>
                  <span>
                  </span>
                </div>

                <div class="cart__area-columns" :key="index" v-for="(cartItem, index) in form.items">

                  <v-select @option:selected="(selected) => fillItemInfoForm(index, selected, cartItem)" v-model="cartItem.itemModel" @search="ajaxSearch" :options="searchItems"/>

                  <b-form-input
                    v-model="cartItem.qty"
                    type="text"
                    placeholder="Enter Qty"
                    @change="(payload) => changeItemInfoQty(index, cartItem)"
                  ></b-form-input>

                  <b-btn variant="danger" @click="cartRemoveItem(index)">
                    <b-icon-trash/>
                  </b-btn>
                </div>

                <div ref="errorPage" tabindex="-1" id="stock-error" class="alert alert-danger py-1" v-if="stockError.length > 0">
                  <ul>
                    <li v-for="(error, index) in Object.values(stockError)" :key="index">
                      {{ error }}
                    </li>
                  </ul>
                </div>

                <b-btn @click="cartAddItem" class="my-2" variant="success">
                  Add Item
                </b-btn>

              </div>
            </div>

          <b-btn @click="testCheckStock" variant="primary">
            Check Stock
          </b-btn>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import lodash from 'lodash';
import axios from '@/axios';

export default {
  data() {
    return {
      form: {
        items: [{}],
      },
      searchItems: [],
      stockError: {
      },
    }
  },
  mounted(){
    this.getItemsForDealer().then((data) => {
      this.searchItems = data.map((distItem) => {
        //handling item catalog only show at list when status = "" (Active)
        if(distItem.item.status===""){
          //parse number decimal price
          distItem.price = parseFloat(distItem.price.$numberDecimal)
          //temporary use this for better price filter by distribution channel
          return { label: `${distItem.item.description} - ${distItem.item.itemModel} - ${distItem.channel.name}`, ...distItem }
        }
      });
    })
  },
  methods: {
    ...mapActions({
      checkStock: 'sendSAP/checkStock',
      getItemsForDealer: 'item/getDealerItemToOrder',
    }),
    fillItemInfoForm(index, selectedData, cartItem) {
      let data = Object.assign(cartItem, {
        price: selectedData.price,
        qty: 1,
        totalPrice: 1 * selectedData.price,
        itemModel: selectedData.item.itemModel,
        model: selectedData.item.itemModel,
        id: selectedData.item._id,
        channel: selectedData.channel,
      });

      this.form.items.splice(index, 1, data);
    },
    changeItemInfoQty(index, cartItem) {
      let realData = Object.assign(cartItem, {
        totalPrice: cartItem.price * cartItem.qty,
      })

      this.form.items.splice(index, 1, realData);
    },
    cartRemoveItem(index) {
      this.form.items.splice(index, 1);
    },
    cartAddItem() {
      this.form.items.push({});
    },
    ajaxSearch: lodash.debounce(function (search, loading) {
      loading(true);

      this.getItemsForDealer(search).then((data) => {
        this.searchItems = data.map((item) => {
        //handling item catalog only show at list when status = "" (Active)
          if(item.item.status===""){
            return { label: `${item.description} - ${item.itemModel}`, ...item }   
          }
        });

        loading(false);
      }).catch((e) => {
        console.log(e)
      })
    }, 500),
    ajaxItemSelected(item) {
      this.ajaxItem = item;
    },
    testCheckStock() {
      
      let mFormCopy = JSON.parse(JSON.stringify(this.form));

      // mFormCopy.items = mFormCopy.items.map((i) => {
      //   return Object.assign(i, { itemModel: undefined });
      // }).filter((i) => {
      //   let keys = Object.keys(i);
      //   return (keys.length - 1) > 0;
      // });

      // if (mFormCopy.items.length <= 0) {
      //   this.$bvToast.toast('Please Add Item First', {
      //     title: "Failed",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   return;
      // }
      console.log(mFormCopy);
      this.checkStock({ items: mFormCopy.items, sloc:"FG03"}).then((response) => {
        this.stockError = [];

      }).catch((e) => {
        if (e.response.status === 400) {
          this.$bvToast.toast(e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return
        }

        let { error } = e.response.data;
        this.stockError = Object.values(error);

        this.$nextTick(() => {
          // window.location.hash = '#stock-error';
          this.$refs.errorPage.focus();
        });
      });
    },
  }
}
</script>
